import { LeadSource } from '@pinecorpca/evergreen';
/**
 * getSource is used for middleware (edge functions)
 */
export const getSource = (host: string | undefined | null): LeadSource | null => {
  switch (true) {
    case host?.startsWith(LeadSource.Wealthsimple.toLowerCase()):
    case host?.startsWith('money'): // TODO - remove once testing is complete
      return LeadSource.Wealthsimple;
    case host?.toLowerCase()?.startsWith('rogersbank'): // TODO remove when demo complete https://pinecanada.atlassian.net/browse/CUS-4641
      return 'ROGERSBANKS' as LeadSource;
    default:
      return null;
  }
};
