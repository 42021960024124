import { AddButton } from '@containers/long-app/components';
import FormStyles from '@containers/long-app/styles/Form.styles';
import TypeFormsStyles from '@containers/long-app/styles/TypeForms.styles';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { BorrowerType } from '@pinecorpca/evergreen';
import { Button } from '@pinecorpca/spruce';
import Checkbox from '@spruce/Checkbox/Checkbox';
import {
  useAdditionalPropertyMutationOnPost,
  useAdditionalPropertiesQuery,
  useCoOwnerPropertyMutationOnDelete,
  useCoOwnerPropertyMutationOnPost,
} from 'hooks/queries/additional-properties.hooks';
import { useBorrowerQuery } from 'hooks/queries/borrower.hooks';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { formatAddress } from 'utils/formatAddress.util';
import PropertyFormList from '../propertyForm/PropertyFormList';

type Props = {
  onSubmit: () => void;
};

const PropertyMain = ({ onSubmit }: Props) => {
  const {
    query: { borrowerId },
  } = useRouter();
  const { t } = useTranslation(NAMESPACE.LONGAPP);
  const { additionalProperties } = useAdditionalPropertiesQuery();
  const { primaryBorrower, data: currentBorrower } = useBorrowerQuery(borrowerId as string);
  const { mutate: propertyMutationOnPost, isPending: isCreatePropertyLoading } = useAdditionalPropertyMutationOnPost();
  const { mutateAsync: coOwnerMutationOnPost, isPending: coOwnerPostLoading } = useCoOwnerPropertyMutationOnPost();
  const { mutateAsync: coOwnerMutationOnDelete, isPending: coOwnerDeleteLoading } = useCoOwnerPropertyMutationOnDelete();
  const methods = useForm({ mode: 'onBlur' });

  const sortedAdditionalProperties = useMemo(
    () => additionalProperties.sort((a, b) => (a.creationDate && b.creationDate ? (a.creationDate > b.creationDate ? 1 : -1) : 0)),
    [additionalProperties]
  );
  const hasCoOwnedProperties = useMemo(
    () => primaryBorrower?.additionalProperties?.some(({ coOwners }) => coOwners?.includes(borrowerId as string)),
    [primaryBorrower?.additionalProperties, borrowerId]
  );
  const isValidProperties = useMemo(
    () => !!sortedAdditionalProperties.length || hasCoOwnedProperties,
    [sortedAdditionalProperties, hasCoOwnedProperties]
  );

  const onFormSubmit = useCallback(() => {
    if (isValidProperties) onSubmit();
  }, [isValidProperties, onSubmit]);

  const addProperty = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const body = { propertyAddress: {} };
      propertyMutationOnPost({
        borrowerId: borrowerId as string,
        propertyDto: body,
      });
    },
    [propertyMutationOnPost, borrowerId]
  );

  const handleOnCoOwnerClick = useCallback(
    async (e: ChangeEvent<HTMLInputElement>, borrowerId: string, propertyId: string) => {
      const requestFunction = e.target.checked ? coOwnerMutationOnPost : coOwnerMutationOnDelete;
      await requestFunction({ borrowerId, propertyId });
    },
    [coOwnerMutationOnDelete, coOwnerMutationOnPost]
  );

  return (
    <FormProvider {...methods}>
      <TypeFormsStyles.Form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <TypeFormsStyles.FormGroupWrapper>
          <PropertyFormList additionalProperties={sortedAdditionalProperties} loading={isCreatePropertyLoading} />
        </TypeFormsStyles.FormGroupWrapper>

        {!!primaryBorrower?.additionalProperties?.length && currentBorrower.borrowerType === BorrowerType.Cosigner && (
          <>
            <FormStyles.FormCue>{t('ASSETS_PROPERTY_WITH_PRIMARY_BORROWER', { firstName: currentBorrower?.firstName })}</FormStyles.FormCue>
            {primaryBorrower?.additionalProperties.map((properties) => (
              <Checkbox
                data-testid={`co-owner-checkbox-${properties.id}`}
                disabled={coOwnerDeleteLoading || coOwnerPostLoading}
                checked={properties?.coOwners?.includes(borrowerId as string)}
                key={properties.id}
                id={properties.id as string}
                name={properties.id as string}
                label={formatAddress(properties.propertyAddress) as string}
                onChange={(e) => handleOnCoOwnerClick(e, borrowerId as string, properties.id as string)}
              />
            ))}
          </>
        )}
        <AddButton onClick={addProperty} data-testid="add-property-button">
          {t('PROPERTY_ADD_ANOTHER')}
        </AddButton>
        {!!isValidProperties && (
          <Button data-testid="submit-properties" type="submit" style={{ width: 'max-content' }}>
            {t('SAVE_CONTINUE')}
          </Button>
        )}
      </TypeFormsStyles.Form>
    </FormProvider>
  );
};

export default PropertyMain;
