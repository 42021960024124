import { FlowNode } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import { OptionStruct } from '@models/option.model';
import { BorrowerType, DetailedBorrowerFullDto, DwellingStatus, PropertyOccupationType } from '@pinecorpca/evergreen';
import { areAddressesEqual, isTrue } from 'utils';
import { BorrowerWithPropertyOccupationType } from '../models/assets.model';

const getPrimaryResidence = (borrower: BorrowerWithPropertyOccupationType) => {
  if (borrower?.dwellingStatus !== DwellingStatus.Own || borrower?.propertyOccupationType !== PropertyOccupationType.OwnerOccupied)
    return null;

  const currentAddress = borrower?.addressHistories?.find((address) => address.isCurrent);
  return borrower?.additionalProperties?.find((property) => areAddressesEqual(property.propertyAddress, currentAddress?.address));
};

export const isDownpaymentComplete = (borrower: DetailedBorrowerFullDto) => {
  const { downPayments } = borrower ?? {};
  return !!downPayments?.length && downPayments?.every((downpayment) => !!downpayment.amount);
};

export const isListedPropertyComplete = (borrower: DetailedBorrowerFullDto) => {
  const { hasAdditionalProperties } = borrower ?? {};
  return hasAdditionalProperties != null;
};

export const listedPropertySubmitHandler = (borrower: BorrowerWithPropertyOccupationType) => {
  const { hasAdditionalProperties } = borrower ?? {};
  if (hasAdditionalProperties == null) return '';

  if (hasAdditionalProperties) {
    return 'property_types';
  }

  const hasPrimaryResidence = getPrimaryResidence(borrower);

  if (hasPrimaryResidence) {
    return 'intended_disposition';
  }

  return FlowState.NEXTSECTION;
};

export const additionalPropertySubmitHandler = (borrower: BorrowerWithPropertyOccupationType) => {
  const hasPrimaryResidence = getPrimaryResidence(borrower);

  if (hasPrimaryResidence) {
    return 'intended_disposition';
  }

  return FlowState.NEXTSECTION;
};

export const isAdditionalPropertiesComplete = (borrower: DetailedBorrowerFullDto) => {
  const additionalProperties = borrower?.additionalProperties;
  const isCoBorrower = borrower?.borrowerType === BorrowerType.Cosigner;
  const isPropertyIncomplete = !!additionalProperties?.find(
    (property) =>
      !(
        property.propertyAddress?.streetName &&
        property.propertyAddress?.streetNumber &&
        property.propertyAddress?.city &&
        property.propertyAddress?.province &&
        property.propertyAddress?.postalCode
      )
  );
  return (!!additionalProperties?.length && !isPropertyIncomplete) || (isCoBorrower && !!borrower.hasAdditionalProperties);
};

export const isIntendedDispositionComplete = (borrower: BorrowerWithPropertyOccupationType) => {
  const primaryResidence = getPrimaryResidence(borrower);
  return primaryResidence?.intendedDisposition != null;
};

export const constructIntendedDispositionValue = (borrower: BorrowerWithPropertyOccupationType) => {
  const primaryResidence = getPrimaryResidence(borrower);
  return primaryResidence?.intendedDisposition ?? '';
};

export const getNextQId = ({
  question,
  answer,
  borrower,
}: {
  question: FlowNode;
  answer: Record<string, any>;
  borrower: BorrowerWithPropertyOccupationType;
}) =>
  question?.submitHandler?.({
    ...borrower,
    ...(question?.id === 'any_additional_properties' ? { hasAdditionalProperties: isTrue(answer.target?.value) } : {}),
  }) ?? question?.options?.find((option: OptionStruct) => option.value === answer.target?.value)?.next;
