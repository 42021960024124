import { useContext, useEffect, useRef } from 'react';
import { AmplitudeContext } from './AmplitudeContext';

type AmplitudePageViewEvent = {
  section: string;
  step: string;
};

export const useAmplitude = (event?: string, amplitudePageViewEvent?: AmplitudePageViewEvent) => {
  const context = useContext(AmplitudeContext);
  const mountRef = useRef(false);

  if (context === undefined) throw new Error('useAmplitude must be used within a AmplitudeContextProvider');

  useEffect(() => {
    if (amplitudePageViewEvent && event && !mountRef.current) {
      mountRef.current = true;
      context.trackEvent(event, {
        section: amplitudePageViewEvent.section,
        trigger: 'page viewed',
      });
    }
  }, [amplitudePageViewEvent, context, event]);

  return context;
};
