import config from '@config';
import { axiosInstance } from 'api/evergreen';
import { getCookie } from 'cookies-next';
import { useCallback } from 'react';

export const useAuthentication = () => {
  const isAuthenticated = getCookie(config.IS_AUTHENTICATED_NAME)?.valueOf() as boolean;
  // refreshToken relies on a refresh token http only cookie,
  // so it doesn't take any parameters.
  // Always call the successCallback, since failure just means that
  // the access token was not able to be refreshed.
  const updateToken = useCallback(() => axiosInstance.get('/api/users/token_refresh'), []);

  const doLogin = useCallback(async (redirectUri?: string, unblock?: boolean) => {
    const response = await axiosInstance.get('/api/users/login-url', {
      params: {
        callbackUri: `${window.location?.origin}/oauth_callback`,
        redirectUri: redirectUri || window.location?.href,
        unblock: unblock || false,
      },
    });
    window.location.href = response.data;
  }, []);

  const doLogout = useCallback(async (login = false) => {
    const redirectUri = login ? `${window.location.origin}/logout_callback?login=true` : `${window.location.origin}/logout_callback`;
    // This will remove the cookies to log us out from backend's POV.
    // Backend will then return a URL that we need to navigate to in
    // order to log out of Auth0.
    const response = await axiosInstance.get('/api/users/logout', {
      params: {
        redirectUri,
      },
    });
    window.location.href = response.data;
  }, []);

  const exchangeCode = useCallback(async (code: string, redirectUri: string, callback: () => void) => {
    try {
      await axiosInstance.post('/api/users/token_exchange', {
        code,
        redirectUri,
      });
      callback();
    } catch (_) {
      callback();
    }
  }, []);

  return {
    isAuthenticated,
    updateToken,
    doLogin,
    doLogout,
    exchangeCode,
  };
};
