import Link from 'next/link';
import styled from 'styled-components';
import { StepStatus } from './models/stepIndicator.model';

const StyledIndicator = {
  Nav: styled.nav`
    position: relative;
    width: 100px;
    display: grid;
    grid-template-columns: 1px 94px;
    column-gap: 20px;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    list-style: none;
    padding: 18px 0;
    margin: 0;
  `,
  ListItem: styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
  ItemText: styled.p<{ $status: StepStatus; $current: boolean }>`
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    align-items: center;
    color: ${({ theme, $current }) => ($current ? theme.text.primary : theme.text.secondary)};
    font-family: var(--font-futura);
    font-weight: ${({ $current }) => ($current ? '450' : '300')};
    font-size: 14px;
    line-height: 17px;
    white-space: break-spaces;
    cursor: default;
  `,
  ItemLink: styled(Link)<{ $status: StepStatus; $current: boolean }>`
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    align-items: center;
    text-decoration: none;
    pointer-events: ${({ $status }) => ($status !== StepStatus.INCOMPLETE ? 'auto' : 'none')};
    color: ${({ theme, $current }) => ($current ? theme.text.primary : theme.text.secondary)};
    font-family: var(--font-futura);
    font-weight: ${({ $current }) => ($current ? '450' : '300')};
    font-size: 14px;
    line-height: 17px;
    white-space: break-spaces;
  `,

  ChildWrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px 15.5px;
    padding-bottom: 8px;
    box-shadow: 1px 0 0 0 ${({ theme }) => theme.palette.granite.default};
    position: relative;
  `,
  ProgressIndicatorLine: styled.div`
    position: absolute;
    width: 1px;
    left: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.text.primary};
    transform-origin: top;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
    z-index: 1;
  `,
  BaseProgressBar: styled.div`
    width: 1px;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.granite.tint};
    transform: translateZ(0); // enables hardware acceleration
  `,
  MobileStepLabel: styled.h2`
    white-space: nowrap;
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  `,
  MobileNav: styled.nav`
    position: relative;
    height: 40px;
    padding: 0 20px;
  `,
  ProgressIndicatorWrapper: styled.div`
    position: absolute;
    width: 1px;
    left: 0;
    height: 100%;
    overflow: hidden;
  `,
  MobileProgressIndicatorLine: styled.div`
    position: absolute;
    top: 20px;
    transform-origin: left;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.text.primary};
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
    z-index: 1;
  `,
  MobileBaseProgressBar: styled.div`
    position: relative;
    top: 6px;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.palette.granite.tint};
    transform: translateZ(0); // enables hardware acceleration
  `,
};

export default StyledIndicator;
