import { NAMESPACE } from '@models/enums/namespace.enum';
import { AddressDto, DwellingStatus } from '@pinecorpca/evergreen';
import { useAdditionalPropertiesQuery } from 'hooks';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import ListedPropertyCard from './ListedPropertyCard';
import Styles from './ListedPropertyCard.styles';

type PropertyType = AddressDto & { tag: DwellingStatus };

interface ListedPropertyProps {
  hideHint?: boolean;
}

const ListedProperty = ({ hideHint = false }: ListedPropertyProps) => {
  const { rentalIncomeProperties, primaryAdditionalProperty } = useAdditionalPropertiesQuery();
  const { t } = useTranslation(NAMESPACE.LONGAPP);

  const propertyList = useMemo(() => {
    const rentalProperties =
      rentalIncomeProperties.map((property) => ({
        ...property.propertyAddress,
        tag: DwellingStatus.Rent,
      })) || ([] as PropertyType[]);

    const primaryProperty = primaryAdditionalProperty
      ? { ...primaryAdditionalProperty.propertyAddress, tag: DwellingStatus.Own }
      : undefined;

    if (!primaryProperty) return rentalProperties;

    return [primaryProperty, ...rentalProperties];
  }, [rentalIncomeProperties, primaryAdditionalProperty]);

  return (
    <>
      {!!propertyList?.length && (
        <>
          <Styles.Header>{t('LISTED_PROPERTY_HEADING')}</Styles.Header>
          <Styles.CardDeck>
            {propertyList.map((address: PropertyType | undefined) => (
              <ListedPropertyCard address={address} key={`address_${address?.id}`} />
            ))}
          </Styles.CardDeck>
          {!hideHint && <Styles.Hint data-testid="listed-property-hint">{t('LISTED_PROPERTY_HINT')}</Styles.Hint>}
        </>
      )}
    </>
  );
};

export default ListedProperty;
