import { useStepIndicator } from './state/IndicatorContext';
import IndicatorItem from './IndicatorItem';
import { Step } from './models/stepIndicator.model';
import Styled from './StepIndicator.styles';
import { useEffect, useRef } from 'react';

const StepIndicator = () => {
  const { state } = useStepIndicator();
  const progressLineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const progressLine = progressLineRef.current;

    if (!progressLine) return;

    const total = state.steps.reduce((acc, step) => acc + step.totalProgress, 0);
    const current = state.steps.reduce((acc, step) => acc + step.currentProgress, 0);
    const scaleRatio = Math.min(current / total, 1);

    progressLine.style.transform = `scaleY(${scaleRatio})`;
  }, [state.steps]);

  return (
    <Styled.Nav data-testid="step-indicator">
      <Styled.ProgressIndicatorWrapper>
        <Styled.ProgressIndicatorLine ref={progressLineRef} />
      </Styled.ProgressIndicatorWrapper>
      <Styled.BaseProgressBar />
      <Styled.List>
        {state.steps.map((step: Step) => (
          <Styled.ListItem key={step?.to || step.label}>
            <IndicatorItem step={step} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Nav>
  );
};

export default StepIndicator;
