import { useTranslation } from 'next-i18next';
import { useStepIndicator } from './state/IndicatorContext';
import Styled from './StepIndicator.styles';
import { useEffect, useRef } from 'react';
import { NAMESPACE } from '@models/enums';

const MobileStepIndicator = () => {
  const { t } = useTranslation(NAMESPACE.LONGAPP);
  const progressLineRef = useRef<HTMLDivElement>(null);
  const { state } = useStepIndicator();

  const currentStep = state.steps.find((step) => step.current);

  /**
   * Calculate the width of the progress line based on the current progress of the steps
   * limited to the max width of the base progress bar
   */
  useEffect(() => {
    const progressLine = progressLineRef.current;

    if (!progressLine) return;

    const total = state.steps.reduce((acc, step) => acc + step.totalProgress, 0);
    const current = state.steps.reduce((acc, step) => acc + step.currentProgress, 0);

    const scaleRatio = Math.min(current / total, 1);

    progressLine.style.transform = `scaleX(${scaleRatio})`;
  }, [state.steps]);

  return (
    <Styled.MobileNav>
      {currentStep?.label && <Styled.MobileStepLabel>{t(currentStep.label)}</Styled.MobileStepLabel>}
      <Styled.MobileProgressIndicatorLine ref={progressLineRef} />
      <Styled.MobileBaseProgressBar />
    </Styled.MobileNav>
  );
};

export default MobileStepIndicator;
