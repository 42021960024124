import { addressKeys } from '@/lib/constants';
import { Provinces } from '@components/AddressForm/provinces.constants';
import { AddressDto, Province } from '@pinecorpca/evergreen';
import { format, isMatch, parse } from 'date-fns';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

export enum Order {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

/**
 * This needs to be used to handle translations
 * instead of creating a nested mess, we'll use province codes to the advisor
 * as they are traditionally used in Canada.
 */
export const provinceMapper: Record<string, string> = {
  [Province.Alberta]: 'AB',
  [Province.BritishColumbia]: 'BC',
  [Province.Manitoba]: 'MB',
  [Province.NewfoundlandAndLabrador]: 'NL',
  [Province.NewBrunswick]: 'NB',
  [Province.NorthwestTerritories]: 'NT',
  [Province.NovaScotia]: 'NS',
  [Province.Nunavut]: 'NU',
  [Province.Ontario]: 'ON',
  [Province.PrinceEdwardIsland]: 'PE',
  [Province.Quebec]: 'QC',
  [Province.Saskatchewan]: 'SK',
  [Province.Yukon]: 'YT',
};

/**
 * construct address string
 *
 * @param {object} address - address object containing address info
 * @return {string}
 */
export const constructAddressString = (address: AddressDto | undefined) => {
  if (!address) return '';
  const province = Provinces.find((prov) => prov.value === address.province)?.label;
  return `${address.addressLine2 ? address.addressLine2.concat(' - ') : ''}${address.streetNumber ?? ''} ${
    address.streetName ? address.streetName.concat(', ') : ''
  }${address.city ? address.city.concat(', ') : ''}${province ? provinceMapper[province as Province].concat(', ') : ''}Canada ${
    address.postalCode ?? ''
  }`;
};

/**
 * Convert to Currency Format
 *
 * @param {string | number| null | undefined} num
 */
export const formatCurrency = (num: string | number | null | undefined, options?: Intl.NumberFormatOptions | undefined) => {
  if (Number.isNaN(Number(num)) || !num) return '';
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    ...options,
  }).format(Number(num));
};

/**
 * Convert to Percent Format
 *
 * @param { number | null | undefined} num
 */
export const formatPercent = (num: number | null | undefined, options?: Intl.NumberFormatOptions & { allowZero?: boolean }) => {
  if (!num && !options?.allowZero) return undefined;
  if (num == null) return undefined;

  return new Intl.NumberFormat('en-CA', {
    style: 'percent',
    maximumFractionDigits: 2,
    ...options,
  }).format(Number(num) / 100);
};

/**
 * format given date in MMMM d, yyyy
 *
 * @param date
 * @returns string
 */
export const formatDate = (date?: string) => {
  if (!date) return '';
  const dateFormat = isMatch(date, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
  return format(parse(date, dateFormat, new Date()), 'MMMM d, yyyy ');
};

/**
 * Massage string by removing trailing spaces and extra spaces between words
 * Eg: " 123    Main   St   " => "123 Main St"
 * @param {string | undefined} text
 * @returns {string | undefined}
 */
export const plainText = (text: string | undefined) => text?.replace(/\s+/g, ' ')?.trim()?.toLocaleLowerCase() as any;

/**
 * Mapping Object
 * @param {Record} obj
 * @param {(arg: string) => string} operator
 * @returns {Record}
 */
export const mappingObject = (obj: Record<string, any>, operator: (arg: string) => string) =>
  Object.keys(obj).reduce((acc, curr) => ({ ...acc, [curr]: operator(obj[curr]) }), {});

/**
 * check if two addresses are the same
 * @param {AddressDto | undefined} mainAddress
 * @param {AddressDto | undefined} secondAddress
 * @returns {boolean}
 */
export const commonAreAddressesEqual = (mainAddress: AddressDto | undefined, secondAddress: AddressDto | undefined): boolean => {
  if (!mainAddress || !secondAddress) return false;

  const mainAddressObj = pick(mainAddress, addressKeys);
  const newMainAddressObj = mappingObject(mainAddressObj, plainText);
  const secondAddressObj = pick(secondAddress, addressKeys);
  const newSecondAddressObj = mappingObject(secondAddressObj, plainText);

  return isEqual(newMainAddressObj, newSecondAddressObj);
};

/**
 * Used to build the payload for json patch (backend patch requests)
 * @param obj object to be iterated
 * @param notation notation to be added to the key path
 * @returns an object of keys with flattened notation paths, e.g. { a: { b: { c: 1 } } } => { '/a/b/c': 1 }
 */
export const objectToNotation = (obj: Record<string, any>, notation = '/') => {
  let notationObj = {};
  const recursive = (obj: Record<string, any>, keyName: string) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const newKey = keyName + key;
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          recursive(item, `${newKey}${notation}${index}${notation}`);
        });
      } else if (value && typeof value === 'object') {
        recursive(value, `${newKey}${notation}`);
      } else {
        notationObj = { ...notationObj, [newKey]: value };
      }
    });
  };
  recursive(obj, notation);
  return notationObj;
};

export const isTrue = (value: string | boolean): boolean => {
  if (typeof value === 'boolean') return value;
  return value === 'true';
};
