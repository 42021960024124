import { LONG_APP_ROUTES } from '@models/enums';
import { API } from '@models/enums/api.enum';
import { FlowRoot } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import { BorrowerType, IntendedDisposition } from '@pinecorpca/evergreen';
import Downpayments from '../components/downpayments/Downpayments';
import ListedProperty from '../components/listedProperty/ListedProperty';
import PropertyMain from '../components/propertyMain/PropertyMain';
import {
  additionalPropertySubmitHandler,
  constructIntendedDispositionValue,
  isAdditionalPropertiesComplete,
  isDownpaymentComplete,
  isIntendedDispositionComplete,
  isListedPropertyComplete,
  listedPropertySubmitHandler,
} from '../utils/assets.utils';

export const AssetsForm = (borrowerType: BorrowerType | undefined, isSwitchRefinance: boolean): FlowRoot => ({
  root: borrowerType === BorrowerType.Cosigner || isSwitchRefinance ? 'any_additional_properties' : 'downpayments',
  id: 'assets',
  pageTitle: 'Assets',
  flowName: 'assets',
  nextSecUrl: `/applications/:applicationId/${LONG_APP_ROUTES.ADDITIONAL_BORROWERS}`,
  downpayments: {
    id: 'downpayments',
    type: 'customized',
    header: 'ASSETS_DOWNPAYMENT',
    component: Downpayments,
    next: 'any_additional_properties',
    isCompleteHelper: isDownpaymentComplete,
    skipApiCall: true,
    dataPath: API.BORROWER,
  },
  any_additional_properties: {
    id: 'any_additional_properties',
    header: borrowerType === BorrowerType.Primary ? 'ASSETS_OWN_ANY' : 'ASSETS_OWN_ANY_CO',
    type: 'options',
    fieldName: 'hasAdditionalProperties',
    api: API.BORROWER,
    dataPath: API.BORROWER,
    next: FlowState.DYNAMIC,
    subHeader: ListedProperty,
    isCompleteHelper: isListedPropertyComplete,
    submitHandler: listedPropertySubmitHandler,
    options: [
      {
        id: 'additional_properties_yes',
        value: 'true',
        label: 'COMMON_YES',
        next: 'property_types',
      },
      {
        id: 'additional_properties_no',
        value: 'false',
        label: 'COMMON_NO',
        next: FlowState.NEXTSECTION,
      },
    ],
  },
  property_types: {
    id: 'property_types',
    type: 'customized',
    component: PropertyMain,
    header: borrowerType === BorrowerType.Primary ? 'ASSETS_MORE_ABOUT_PROPERTY' : 'ASSETS_MORE_ABOUT_PROPERTY_CO',
    next: FlowState.DYNAMIC,
    isCompleteHelper: isAdditionalPropertiesComplete,
    submitHandler: additionalPropertySubmitHandler,
    skipApiCall: true,
    dataPath: API.BORROWER,
  },
  intended_disposition: {
    id: 'intended_disposition',
    type: 'options',
    subHeader: ListedProperty,
    isCompleteHelper: isIntendedDispositionComplete,
    constructValueHelper: constructIntendedDispositionValue,
    subHeaderProps: {
      hideHint: true,
    },
    header: 'ASSETS_INTENDED_DISPOSITION',
    next: FlowState.NEXTSECTION,
    api: API.ADDITIONAL_PROPERTY,
    dataPath: API.BORROWER,
    fieldName: 'intendedDisposition',
    options: Object.values(IntendedDisposition).map((value) => ({
      id: value,
      value: value,
      label: `ASSETS_${value}`,
      next: FlowState.NEXTSECTION,
    })),
  },
});
