import AddButton from '@containers/long-app/components/AddEntryButton/AddButton';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { BorrowerType, DetailedIncomeDto, IncomeType } from '@pinecorpca/evergreen';
import { Button } from '@pinecorpca/spruce';
import OptionGroup from '@spruce/Option/OptionGroup';
import { LabelBold } from '@spruce/Typography';
import { useBorrowerQuery } from 'hooks/queries/borrower.hooks';
import { useIncomeMutationOnDelete, useIncomeMutationOnPatch, useIncomeMutationOnPost, useIncomeQuery } from 'hooks/queries/incomes.hooks';
import { useResponsive } from 'hooks/responsive';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import pLimit from 'p-limit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { theme } from 'styles/theme';
import TypeFormsStyles from '../../../../styles/TypeForms.styles';
import { formatOptionLabel, incomeTypes } from '../../config/incomeOptions.constants';
import { focusFieldMapper } from '../../constants/income.contants';
import { SkeletonType } from '../../models/income.models';
import { createIncomePayload, incomeFormBuilder } from '../../utils/income-builders.utilts';
import { buildIncomePatchPayload, currentIncomesSubmitHandler, isPreviousIncomesComplete } from '../../utils/incomes.utils';
import IncomeForm from './incomeForm/IncomeForm';
import Skeleton from './incomeForm/Skeleton';
import { useAmplitude } from 'contexts/amplitude';

const limit = pLimit(1);

type Props = {
  isCurrent: boolean;
  onSubmit: (arg?: any) => void;
};

const IncomesMain = ({ isCurrent, onSubmit }: Props) => {
  const mountRef = useRef(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const addCtaRef = useRef<HTMLButtonElement>(null);
  const incomeRefs = useRef<HTMLDivElement[] | null[]>([]);
  const [formEvent, setFormEvent] = useState<'append' | 'remove' | 'add' | 'default'>('default');
  const { isTabletOrMobile } = useResponsive();
  const {
    query: { borrowerId },
  } = useRouter();
  // default to true as we want are determine when the form is done populating
  const [populating, setPopulating] = useState(true);
  const { data: borrower } = useBorrowerQuery(borrowerId as string);
  const { t } = useTranslation(NAMESPACE.LONGAPP);
  const { data: detailedIncomes = [] } = useIncomeQuery(borrowerId as string);
  const { mutateAsync: createIncome } = useIncomeMutationOnPost();
  const { mutateAsync: incomeMutationOnDelete, isPending: isDeleteIncomeLoading } = useIncomeMutationOnDelete();
  const { mutateAsync: incomeMutationOnPatch, isPending: isPatchIncomeLoading } = useIncomeMutationOnPatch();
  const [incomeExpand, setIncomeExpand] = useState(false);
  const [incomeTypeLoading, setIncomeTypeLoading] = useState<SkeletonType>(undefined);
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>(undefined);
  const { trackEvent } = useAmplitude(`${borrower?.borrowerType === BorrowerType.Primary ? 'Applicant' : 'Co-Applicant'} Incomes Viewed`, {
    section: borrower?.borrowerType === BorrowerType.Primary ? 'personal-info' : 'co-applicant-info',
    step: 'incomes-viewed',
  });

  const methods = useForm<{ incomes: DetailedIncomeDto[] }>({ mode: 'onChange' });

  const incomeOptions = useMemo(() => {
    const isPrimary = borrower?.borrowerType === BorrowerType.Primary;
    return incomeTypes
      .filter(({ id }) => id !== IncomeType.Rental || isCurrent)
      .map((option) => ({
        ...option,
        label: t(formatOptionLabel(option, isCurrent, isPrimary) as string),
      }));
  }, [borrower, isCurrent, t]);

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'incomes',
  });

  useEffect(() => {
    if (!mountRef.current && detailedIncomes.length) {
      const formIncomes = detailedIncomes
        ?.filter(({ current, incomeType }) => {
          if (isCurrent) {
            return current || incomeType === IncomeType.Rental;
          }
          return !current && incomeType !== IncomeType.Rental;
        })
        ?.sort((a, b) => ((a?.creationDate ?? '') > (b?.creationDate ?? '') ? 1 : -1))
        ?.map((income) => incomeFormBuilder(income));
      methods.reset({ incomes: formIncomes });
      mountRef.current = true;
      return;
    }
    setPopulating(false);
  }, [detailedIncomes, isCurrent, methods]);

  useEffect(() => {
    if (deleteIndex !== undefined) {
      remove(deleteIndex);
      setDeleteIndex(undefined);
      setFormEvent('remove');
    }
  }, [deleteIndex, remove]);

  useEffect(() => {
    if (!populating) setIncomeExpand(!fields.length);
  }, [populating, fields.length]);

  useEffect(() => {
    incomeRefs.current = incomeRefs.current.slice(0, fields.length);
  }, [fields]);

  useEffect(() => {
    const scrollIntoViewOptions: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: isTabletOrMobile ? 'start' : 'center',
    };

    switch (formEvent) {
      case 'add': {
        optionsRef?.current?.scrollIntoView(scrollIntoViewOptions);
        break;
      }
      case 'append': {
        const ref = incomeRefs.current[fields.length - 1];
        ref?.scrollIntoView(scrollIntoViewOptions);
        break;
      }
      case 'remove': {
        addCtaRef.current?.scrollIntoView(scrollIntoViewOptions);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formEvent, isTabletOrMobile]);

  const notify = useCallback(
    () =>
      toast.warning(t('PREVIOUS_INCOMES_SUBHEADER'), {
        position: toast.POSITION.BOTTOM_RIGHT,
        icon: <Image src="/images/bell-warning.svg" width={20} height={22} alt="" />,
        progressStyle: { background: theme.palette.wheat.default },
        style: {
          color: theme.palette.wheat.default,
          fontFamily: 'Heebo Light',
          fontSize: '14px',
          lineHeight: '21px',
        },
      }),
    [t]
  );

  const onFormSubmit = useCallback(
    async ({ incomes }: { incomes: DetailedIncomeDto[] }) => {
      await Promise.all(
        incomes.map(async (income) => {
          const payload = buildIncomePatchPayload(income);
          await limit(() => incomeMutationOnPatch({ incomeId: income.id as string, requestBody: payload }));
        })
      );
      if (isCurrent) {
        const nextQId = currentIncomesSubmitHandler(incomes);
        onSubmit(nextQId);
      } else {
        const pass = isPreviousIncomesComplete(incomes);
        if (pass) {
          onSubmit();
        } else {
          notify();
        }
      }
    },
    [incomeMutationOnPatch, isCurrent, notify, onSubmit]
  );

  const addIncome = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIncomeExpand(true);
    setFormEvent('add');
  }, []);

  const deleteIncome = useCallback(
    (index: number, incomeId: string) =>
      incomeMutationOnDelete(
        {
          borrowerId: borrowerId as string,
          incomeId: incomeId as string,
        },
        { onSuccess: () => setDeleteIndex(index) }
      ),
    [borrowerId, incomeMutationOnDelete]
  );

  const onChange = useCallback(
    async (answer: any) => {
      trackEvent(`${borrower?.borrowerType === BorrowerType.Primary ? 'Applicant' : 'Co-Applicant'} Incomes Answered`, {
        section: 'incomes',
        value: answer?.target?.value ?? answer,
      });
      const payload = createIncomePayload(answer.target.value, isCurrent);
      setIncomeTypeLoading(answer.target.value);
      setIncomeExpand(false);
      const { data } = await createIncome({
        borrowerId: borrowerId as string,
        incomeDto: payload,
      });
      setIncomeTypeLoading(undefined);
      append(data, { focusName: `incomes.${fields.length}.${focusFieldMapper[answer.target.value as keyof typeof focusFieldMapper]}` });
      setFormEvent('append');
    },
    [append, borrower?.borrowerType, borrowerId, createIncome, fields.length, isCurrent, trackEvent]
  );

  return (
    <FormProvider {...methods}>
      <TypeFormsStyles.Form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <TypeFormsStyles.FormGroupWrapper>
          {fields.map(({ id, ...rest }, index) => (
            <div key={id}>
              <IncomeForm
                ref={(el) => (incomeRefs.current[index] = el)}
                isDeleting={isDeleteIncomeLoading}
                onDelete={deleteIncome}
                income={rest as DetailedIncomeDto & { incomeId: string }}
                index={index}
              />
            </div>
          ))}
        </TypeFormsStyles.FormGroupWrapper>
        {incomeTypeLoading && <Skeleton skeletonType={incomeTypeLoading} />}
        {!incomeExpand && (
          <AddButton ref={addCtaRef} onClick={addIncome} data-testid="add-income-button">
            {t('INCOMES_OTHER_ADD_OTHER')}
          </AddButton>
        )}
        {incomeExpand && (
          <>
            {!!fields?.length && <LabelBold>{t('INCOMES_SELECT_ANOTHER')}</LabelBold>}
            <OptionGroup ref={optionsRef} options={incomeOptions} onChange={onChange} disabled={!!incomeTypeLoading} />
          </>
        )}
        {!!fields?.length && (
          <Button
            data-testid="forms-submitBtn"
            type="submit"
            style={{ width: '170px', alignSelf: 'flex-end' }}
            loading={isPatchIncomeLoading}
          >
            {t('SAVE_CONTINUE')}
          </Button>
        )}
      </TypeFormsStyles.Form>
    </FormProvider>
  );
};

export default IncomesMain;
