import { DateConstants } from '@components/DatePicker/picker.constants';
import { FlowNode } from '@models/flow.model';
import { OptionStruct } from '@models/option.model';
import { AddressDto, DetailedAddressHistoryDto, MortgageApplicationClientDto } from '@pinecorpca/evergreen';
import { format, isMatch, parse, subYears } from 'date-fns';
import { formatPersistedDate, isAfterOrEqual } from 'utils';

export const isCurrentAddressComplete = (address: AddressDto | undefined) => {
  if (!address) return false;
  const { streetNumber, streetName, city, province, postalCode } = address;
  return !!(streetNumber && streetName && city && province && postalCode);
};

export const moveInDateWithinThreeYears = (moveInDate: string | undefined) => {
  if (!moveInDate) return false;
  const threeYearsAgo = subYears(new Date(), 3);
  const format = isMatch(moveInDate, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
  const withInThreeYears = isAfterOrEqual(parse(moveInDate, format, new Date()), threeYearsAgo);
  return withInThreeYears;
};

export const getPreviousAddressMoveInDate = (currentAddressMoveInDate: string | undefined) => {
  const moveInDate = formatPersistedDate(currentAddressMoveInDate || '');
  if (!moveInDate) return null;
  const threeYearsAgo = subYears(moveInDate, 3);
  return format(threeYearsAgo, DateConstants.BE_DATE_FORMAT);
};

type CurrentAddressMoveInSubmitHandler = {
  application: MortgageApplicationClientDto;
  currentAddress: DetailedAddressHistoryDto | undefined;
  answer: Record<string, any>;
};

const getCurrentAddressMoveInSubmitHandler = ({ application, currentAddress, answer }: CurrentAddressMoveInSubmitHandler) => ({
  dealPurpose: application.dealPurpose,
  intendedOccupationType: application?.property?.intendedOccupationType,
  propertyAddress: application?.property?.propertyAddress,
  currentAddress: {
    ...currentAddress,
    moveInDate: answer?.current?.moveInDate || currentAddress?.moveInDate,
  },
});

type GetNextQuestionId = {
  question: FlowNode;
  application: MortgageApplicationClientDto;
  currentAddress: DetailedAddressHistoryDto | undefined;
  answer: Record<string, any>;
};

export const getNextQuestionId = ({ question, application, currentAddress, answer }: GetNextQuestionId) => {
  switch (question.id) {
    case 'dwelling_status': {
      return question.submitHandler?.(currentAddress);
    }
    case 'current_address_moveIn': {
      const currentAddressMoveInSubmitHandler = getCurrentAddressMoveInSubmitHandler({
        application,
        currentAddress,
        answer,
      });
      return question.submitHandler?.(currentAddressMoveInSubmitHandler);
    }
    default: {
      return question.options?.find((option: OptionStruct) => option.value === answer.target?.value)?.next;
    }
  }
};
