import { API } from '@models/enums/api.enum';
import {
  BorrowerType,
  PropertyControllerApiApiPropertyPropertyIdCoownerDeleteRequest as CoOwnerDeleteRequest,
  PropertyControllerApiApiPropertyPropertyIdCoownerPostRequest as CoOwnerPostRequest,
  DwellingStatus,
  MortgageApplicationClientDto,
  PropertyControllerApiApiBorrowersBorrowerIdAdditionalPropertiesPostRequest as PropertyAdditionalPostRequest,
  PropertyControllerApiApiPropertyPropertyIdDeleteRequest as PropertyDeleteRequest,
  PropertyControllerApiApiPropertyPropertyIdPatchRequest as PropertyPatchRequest,
  RentalIncome,
} from '@pinecorpca/evergreen';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { propertyApi } from 'api/evergreen';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { areAddressesEqual } from 'utils';
import { useBorrowerQuery } from './borrower.hooks';

export const useAdditionalPropertiesQuery = () => {
  const {
    query: { borrowerId },
  } = useRouter();
  const { data: borrower } = useBorrowerQuery(borrowerId as string);

  const rentalProperties = useMemo(() => {
    return borrower?.additionalProperties?.filter((property) =>
      borrower?.incomes?.some((income) => (income.income as RentalIncome)?.property?.id === property.id)
    );
  }, [borrower?.additionalProperties, borrower?.incomes]);

  const currentAddress = useMemo(() => borrower?.addressHistories?.find((address) => address.isCurrent), [borrower?.addressHistories]);

  const primaryAdditionalProperty = useMemo(
    () =>
      borrower?.dwellingStatus === DwellingStatus.Own
        ? borrower?.additionalProperties?.find((property) => areAddressesEqual(property.propertyAddress, currentAddress?.address))
        : null,
    [borrower?.dwellingStatus, borrower?.additionalProperties, currentAddress?.address]
  );

  const additionalProperties = useMemo(() => {
    return borrower?.additionalProperties
      ?.filter((property) => property.id !== primaryAdditionalProperty?.id)
      ?.filter((property) => !rentalProperties?.some((rentalProperty) => rentalProperty.id === property.id));
  }, [borrower?.additionalProperties, primaryAdditionalProperty, rentalProperties]);

  return {
    additionalProperties: additionalProperties || [],
    rentalIncomeProperties: rentalProperties || [],
    primaryAdditionalProperty,
  };
};

export const useAdditionalPropertyMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: PropertyAdditionalPostRequest) => propertyApi.apiBorrowersBorrowerIdAdditionalPropertiesPost(payload),
    onSuccess: ({ data: property }) => {
      queryClient.setQueryData(
        [API.MORTGAGE_APPLICATION, applicationId as string],
        (current: MortgageApplicationClientDto | undefined) => ({
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === property.borrowerId
              ? {
                  ...borrower,
                  additionalProperties: [...(borrower?.additionalProperties || []), property],
                }
              : borrower
          ),
        })
      );
    },
  });
};

export const useAdditionalPropertyMutationOnPatch = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: PropertyPatchRequest) => propertyApi.apiPropertyPropertyIdPatch(payload),
    onSuccess: ({ data: updatedProperty }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const borrower = current?.borrowers?.find(({ id }) => id === updatedProperty.borrowerId);
        const properties = borrower?.additionalProperties?.map((property) =>
          property.id === updatedProperty.id ? updatedProperty : property
        );
        return {
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === updatedProperty.borrowerId ? { ...borrower, additionalProperties: properties } : borrower
          ),
        };
      });
    },
  });
};

export const useCoOwnerPropertyMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: CoOwnerPostRequest) => propertyApi.apiPropertyPropertyIdCoownerPost(payload),
    onSuccess: ({ data: updateCoOwner }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const mainBorrower = current?.borrowers?.find(({ borrowerType }) => borrowerType === BorrowerType.Primary);
        const properties = mainBorrower?.additionalProperties?.map((property) =>
          property.id === updateCoOwner.propertyId
            ? {
                ...property,
                coOwners: [...(property?.coOwners || []), updateCoOwner.borrowerId as string],
              }
            : property
        );
        return {
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === mainBorrower?.id ? { ...borrower, additionalProperties: properties } : borrower
          ),
        };
      });
    },
  });
};

export const useCoOwnerPropertyMutationOnDelete = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (request: CoOwnerDeleteRequest) => propertyApi.apiPropertyPropertyIdCoownerDelete(request),
    onSuccess: (_, request) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const mainBorrower = current?.borrowers?.find(({ borrowerType }) => borrowerType === BorrowerType.Primary);
        const properties = mainBorrower?.additionalProperties?.map((property) =>
          property.id === request.propertyId
            ? {
                ...property,
                coOwners: property?.coOwners?.filter((coOwner) => coOwner !== request.borrowerId),
              }
            : property
        );
        return {
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === mainBorrower?.id ? { ...borrower, additionalProperties: properties } : borrower
          ),
        };
      });
    },
  });
};

export const useAdditionalPropertyMutationOnDelete = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (request: PropertyDeleteRequest & { borrowerId: string }) => propertyApi.apiPropertyPropertyIdDelete(request),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [API.MORTGAGE_APPLICATION, applicationId as string] }),
  });
};
